import React from "react"
import Layout from "hoc/Layout"
import Header from "containers/Header/Header"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Workers from "containers/Company/Workers"
import { useMediaQuery, useTheme } from "@mui/material"

const OurTeam = () => {
  const breadcrumbsData = [
    {
      title: "Startseite",
      link: "/",
    },
    {
      title: "Über uns",
      link: "/uber-uns/firma",
    },
    "Unser Team",
  ]
  const { ourTeamMain } = useStaticQuery(
    graphql`
      query {
        ourTeamMain: file(name: { eq: "our-team-main" }) {
          id
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 100
              placeholder: NONE
            )
          }
        }
      }
    `
  )

  const mainImage = getImage(ourTeamMain)
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle="Unser Team">
      <Header
        title={"Unser Team"}
        titleDescription={
          "Experten im Bereich Recruiting und Kundenservice. Wenn Sie mit Medira zusammenarbeiten, können Sie sicher sein, dass Sie von den besten Branchenspezialisten betreut werden."
        }
        mainImage={mainImage}
        mainImageAlt={"Unser Team"}
        sx={{ margin: isSm ? "0px 0 35px" : "40px 0 35px" }}
      />
      <Workers />
    </Layout>
  )
}

export default OurTeam
