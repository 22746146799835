import { Grid } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Department from "./Department"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import WorkerCard from "./WorkerCard"

const Workers = () => {
  const data = useStaticQuery(graphql`
    query CorporateDepartment {
      departments: allContentfulDzialWFirmie {
        nodes {
          name
          contentful_id
          pracownik {
            ... on ContentfulPracownik {
              avatar {
                gatsbyImageData(
                  backgroundColor: "#e5f5f7"
                  placeholder: NONE
                  quality: 90
                )
              }
              description {
                raw
              }
              mail
              name
              order
              phoneNumber
              position
              surname
            }
          }
        }
      }

      workers: allContentfulPracownik {
        nodes {
          avatar {
            gatsbyImageData(
              backgroundColor: "#e5f5f7"
              placeholder: NONE
              quality: 90
            )
          }
          description {
            raw
          }
          name
          mail
          order
          phoneNumber
          position
          surname
          contentful_id
        }
      }
    }
  `)

  const departments = data.departments.nodes
  const workers = data.workers.nodes

  const showDepartments = !!departments.find(item => item.pracownik)

  return (
    <Grid>
      {showDepartments ? (
        <>
          {departments.map(department => {
            if (!!department.pracownik) {
              return (
                <Department {...department} key={department.contentful_id} />
              )
            } else {
              return null
            }
          })}
        </>
      ) : (
        <>
          <Hr mb={4} mt={6} />
          <Font42>Medira Büromitarbeiter</Font42>
          <Grid container spacing={4} mt={4} rowSpacing={8}>
            {workers
              .sort((a, b) => a.order - b.order)
              .map(worker => (
                <Grid
                  key={worker.contentful_id}
                  item
                  xs={12}
                  md={6}
                  sx={{ display: "flex", width: "100%" }}
                  justifyContent={{ xs: "center", md: "start" }}
                >
                  <WorkerCard {...worker} />
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default Workers
